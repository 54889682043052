const state = () => ({
  candidateProfile: [],
})

const getters = {
  candidateStages: (state) => state.candidateProfile.stages,
  candidateProfile: (state) => state.candidateProfile,
}

const actions = {
  getCandidateResume({ commit }, { selectiveProcessId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(
        `/selective-processes/${selectiveProcessId}/candidate-resume/${candidateId}`,
        { headers }
      )
      .then(({ data }) => {
        commit('setCandidateResume', data)
        return Promise.resolve(data)
      })
  },
}

const mutations = {
  setCandidateResume(state, candidateProfile) {
    state.candidateProfile = candidateProfile
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
