import {
  redirectWhenAlreadyAuthenticated,
  requiresCompanyAuthentication,
} from '../guards/auth'
import store from '../../store'

import { generalRoutes } from './general'

export const adminRoutes = [
  {
    path: 'admin',
    component: () =>
      import(
        /* webpackChunkName: "group-admin" */ '@/layouts/admin/Dashboard.vue'
      ),
    beforeEnter: requiresCompanyAuthentication,
    children: [
      {
        path: '',
        name: 'HomeAdmin',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/components/home/Admin.vue'
          ),
        meta: {
          title: 'Página inicial',
          subtitle: 'Este é o resumo das atividades recentes da sua empresa',
          requiresAuth: true,
        },
      },
      {
        path: 'notifications',
        name: 'AdminNotificacoes',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/candidate/accounts/Notifications.vue'
          ),
        meta: {
          title: 'Notificações',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/filter-candidates',
        name: 'AdminCandidates',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/candidates/Filter.vue'
          ),
        meta: {
          title: 'Candidatos',
          subtitle: 'Faça buscas no banco de candidatos usando filtros',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/selective-process',
        redirect: '/admin/selective-process/manage',
      },
      {
        path: '/admin/selective-process/manage',
        name: 'SelectiveProcesses',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/selective_process/Index.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/selective-process/add',
        name: 'AddSelectiveProcess',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/selective_process/Add.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          localStorage.removeItem('draftSelectiveProcess')
          store.commit('setCurrentSelectiveProcess', null)
          next()
        },
      },
      {
        path: '/admin/selective-process/added/:id',
        name: 'AddedSelectiveProcess',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/selective_process/Added.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          if (from.name !== 'AddSelectiveProcess') {
            next({ name: 'AddSelectiveProcess' })
          } else {
            next()
          }
        },
      },
      {
        path: '/admin/selective-process/:id(\\d+)',
        name: 'EditSelectiveProcess',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/selective_process/Edit.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/selective-process/:id/candidates',
        name: 'ManageSelectiveProcessCandidates',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/selective_process/ManageCandidates.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/selective-process/:id/candidate/:candidate',
        name: 'CandidateProfile',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/selective_process/CandidateProfile.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/models/questionnaires',
        name: 'Questionnaire',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/models/questionnaires/Index.vue'
          ),
        meta: {
          title: 'Modelos',
          subtitle: 'Adicione e gerencie justificativas e questionários',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/models/questionnaires/add',
        name: 'AddQuestionnaire',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/models/questionnaires/Add.vue'
          ),
        meta: {
          title: 'Modelos',
          subtitle: 'Adicione e gerencie justificativas e questionários',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/models/questionnaires/:id/edit',
        name: 'EditQuestionnaire',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/models/questionnaires/Edit.vue'
          ),
        meta: {
          title: 'Modelos',
          subtitle: 'Adicione e gerencie justificativas e questionários',
          requiresAuth: true,
        },
      },
      {
        path: '/historical-selective-process/:id',
        name: 'SelectiveProcessHistory',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/components/selective_process/SelectiveProcessHistory.vue'
          ),
        meta: {
          title: 'Seleção',
          subtitle: 'Adicione e gerencie processos seletivos',
          requiresAuth: true,
        },
      },
      /* JUSTIFICATIVAS */
      {
        path: '/admin/models/justifications',
        name: 'Justifications',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/models/justifications/Index.vue'
          ),
        meta: {
          title: 'Modelos',
          subtitle: 'Adicione e gerencie justificativas e questionários',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/models/justifications/add',
        name: 'AddJustification',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/models/justifications/Add.vue'
          ),
        meta: {
          title: 'Modelos',
          subtitle: 'Adicione e gerencie justificativas e questionários',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/models/justifications/:id/edit',
        name: 'EditJustification',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/models/justifications/Edit.vue'
          ),
        meta: {
          title: 'Modelos',
          subtitle: 'Adicione e gerencie justificativas e questionários',
          requiresAuth: true,
        },
      },
      /* USERS */
      {
        path: '/admin/users',
        name: 'Users',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/users/Index.vue'
          ),
        meta: {
          title: 'Usuários',
          subtitle: 'Adicione e gerencie os usuários que têm acesso ao sistema',
          requiresAuth: true,
        },
      },
      /* COMPANIES */
      {
        path: '/admin/companies',
        name: 'Companies',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/companies/Index.vue'
          ),
        meta: {
          title: 'Empresas',
          subtitle: 'Adicione e gerencie as empresas do sistema',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/companies/:id/:name',
        name: 'CompanyHistoric',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/companies/CompanyHistoric.vue'
          ),
        meta: {
          title: 'Dados da empresa',
          subtitle: 'Histórico da empresa',
          requiresAuth: true,
        },
        props: true,
      },
      /* REPORTS */
      {
        path: '/admin/reports',
        name: 'Reports',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/reports/Index.vue'
          ),
        meta: {
          title: 'Relatórios',
          subtitle: 'Visualize e faça download de dados estatísticos',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/candidate/:id/:presentation_name',
        name: 'CandidateHistoric',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/candidates/CandidateHistoric.vue'
          ),
        meta: {
          title: 'Candidatos',
          subtitle: 'Histórico do Candidato',
          requiresAuth: true,
        },
        props: true,
      },
      /* HELP */
      {
        path: '/admin/help/bug-report',
        name: 'BugReport',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/help/BugReport.vue'
          ),
        meta: {
          title: 'Ajuda',
          subtitle: 'Reporte um problema',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/help/bug-report-confirmation',
        name: 'SuccessfulBugReported',
        component: () =>
          import(
            /* webpackChunkName: "group-candidates" */ '@/views/admin/help/SuccessfulBugReported.vue'
          ),
        meta: {
          title: 'Problema reportado',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/help/tutorials',
        name: 'Tutorials',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/help/Tutorials.vue'
          ),
        meta: {
          title: 'Ajuda',
          subtitle: 'Aprenda como usar ou reporte problemas com o sistema',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/help/tutorials/:id',
        name: 'TutorialTopic',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/help/TutorialTopic.vue'
          ),
        meta: {
          title: 'Ajuda',
          subtitle: 'Aprenda como usar ou reporte problemas com o sistema',
          requiresAuth: true,
        },
        props: true,
      },
      /* AREAS */
      {
        path: '/admin/general-areas',
        name: 'GeneralAreas',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/general_areas/GeneralAreas.vue'
          ),
        meta: {
          title: 'Áreas Gerais',
          subtitle:
            'Adicione e gerencie as áreas gerais cadastradas no sistema',
          requiresAuth: true,
        },
      },
      /* NOTIFICAÇÕES PERSONALIZADAS */
      {
        path: '/admin/custom-notifications',
        name: 'CustomNotifications',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/notifications/CustomNotifications.vue'
          ),
        meta: {
          title: 'Notificações personalizadas',
          subtitle:
            'Envie uma notificação personalizada para um ou mais candidato',
          requiresAuth: true,
        },
      },
      {
        path: '/admin/custom-notifications/selective-process',
        name: 'SelectiveProcessNotification',
        component: () =>
          import(
            /* webpackChunkName: "group-admin" */ '@/views/admin/notifications/SelectiveProcessNotification.vue'
          ),
        meta: {
          title: 'Notificações personalizadas - Processo Seletivo',
          subtitle:
            'Envie uma notificação personalizada para um ou mais candidato de um processo seletivo',
          requiresAuth: true,
        },
        props: (route) => ({
          message: route.query.message,
          selectedGeneralAreas: route.query.selectedGeneralAreas,
          selectedChannels: route.query.selectedChannels,
          targetType: route.query.targetType,
        }),
      },
      /* GENERAL */
      ...generalRoutes,
    ],
  },
  {
    path: 'admin/login',
    name: 'Login',
    component: () =>
      import(
        /* webpackChunkName: "group-admin" */ '@/views/admin/auth/Login.vue'
      ),
    beforeEnter: redirectWhenAlreadyAuthenticated('/admin'),
    meta: {
      title: 'Login',
    },
  },
]
