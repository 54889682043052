const state = () => ({
  principalReports: [],
  usersReports: [],
  selectiveProcessReports: {},
  candidatesReports: {},
})

const getters = {
  principalReports: (state) => state.principalReports,
  usersReports: (state) => state.usersReports,
  selectiveProcessReports: (state) => state.selectiveProcessReports,
  candidatesReports: (state) => state.candidatesReports,
}

const actions = {
  getPrincipalReports({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios.get('/dashboard/', { headers }).then((response) => {
      commit('setPrincipalReports', response.data)
      return Promise.resolve(response.data)
    })
  },

  getUsersReport({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/dashboard/users', { headers })
      .then((response) => {
        commit('setUsersReports', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSelectiveProcessReport({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/dashboard/selective-process', { params, headers })
      .then((response) => {
        commit('setSelectiveProcessReports', response.data)
        return Promise.resolve(response.data)
      })
  },

  getCandidatesReport({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/dashboard/selective-process/candidates', { headers })
      .then((response) => {
        commit('setCandidatesReports', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setPrincipalReports(state, reports) {
    state.principalReports = reports
  },
  setUsersReports(state, reports) {
    state.usersReports = reports
  },
  setSelectiveProcessReports(state, reports) {
    state.selectiveProcessReports = reports
  },
  setCandidatesReports(state, reports) {
    state.candidatesReports = reports
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
