const state = () => ({})

const getters = {}

const actions = {
  sendCustomNotifications({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post('/notifications/list-users/create', params, { headers })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
}

const mutations = {}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
