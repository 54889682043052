export const generalRoutes = [
  {
    path: 'frequently-asked',
    component: () =>
      import(
        /* webpackChunkName: "group-general" */ '@/views/candidate/general/FrequentlyAskedQuestions.vue'
      ),
    meta: {
      title: 'Perguntas frequentes',
      requiresAuth: false,
    },
  },
  {
    path: 'terms-of-use',
    component: () =>
      import(
        /* webpackChunkName: "group-general" */ '@/views/candidate/general/TermsOfUse.vue'
      ),
    meta: {
      title: 'Termos de uso',
      requiresAuth: false,
    },
  },
  {
    path: 'privacy-policy',
    component: () =>
      import(
        /* webpackChunkName: "group-general" */ '@/views/candidate/general/PrivacyPolicy.vue'
      ),
    meta: {
      title: 'Política de privacidade',
      requiresAuth: false,
    },
  },
]
