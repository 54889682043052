import Vue from 'vue'
import VueRouter from 'vue-router'
import { adminRoutes } from './routes/admin'
import { candidateRoutes } from './routes/candidates'
import { generalRoutes } from './routes/general'
import {
  requiresCandidateAuthentication,
  requiresCompanyAuthentication,
} from './guards/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "group-index" */ '@/layouts/general/Structure.vue'
      ),
    children: [
      ...generalRoutes,
      ...adminRoutes,
      ...candidateRoutes,
      {
        path: '/503',
        name: 'ServiceUnavailable',
        component: () =>
          import(
            /* webpackChunkName: "group-index" */ '@/views/candidate/general/ServiceUnavailable.vue'
          ),
        meta: {
          title: '503 Serviço Indisponível',
        },
      },
      {
        path: '*',
        name: 'Error',
        component: () =>
          import(
            /* webpackChunkName: "group-index" */ '@/views/candidate/general/Error.vue'
          ),
        meta: {
          title: 'Error',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = !to.meta.title
    ? 'Carreira RH'
    : `${to.meta.title} | Carreira RH`

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.path.includes('/admin')) {
      requiresCompanyAuthentication(to, from, next)
    } else {
      requiresCandidateAuthentication(to, from, next)
    }
  } else {
    next()
  }
})

export default router
